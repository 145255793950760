import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
  min-height: 4rem;
  padding: 1rem 0;
  background-color: #000000;
  color: white;
  ul {
    list-style: none;
    li {
      margin: 0.2rem;
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
    }
    p {
      width: 50%;
      margin: auto;
      border-bottom: 1px solid gray;
      text-align: center;
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <ul>
        <p>Pages</p>
        <li>
          <Link to="/about-us/">About Us</Link>
        </li>
        <li>
          <Link to="/privacy-policy/">Privacy Policy</Link>
        </li>
      </ul>
    </StyledFooter>
  )
}

export default Footer
