import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Send32 } from "@carbon/icons-react"
import styled from "styled-components"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const StyledSimpleForm = styled.form`
  width: 100%;
  margin: 1rem auto;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    background-color: #0043ce;
    color: #ffffff;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    box-sizing: border-box;
    height: 52px;
  }

  @media (min-width: 800px) {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
const Input = styled.input`
  max-width: 250px;
  border: ${props => (props.errror ? `2px solid red` : `2px solid #0043ce`)};
  padding: 0.5rem;
  margin: 0.4rem 0;
  box-sizing: border-box;
  height: 54px;
  @media (min-width: 800px) {
    max-width: 400px;
  }
`

export const HeaderForm = () => {
  const { register, handleSubmit, watch, errors } = useForm()

  return (
    <form>
      <input type="text" name="name" placeholder="name" />
      <input type="text" name="email" placeholder="email" />
      <input type="text" name="phone-model" placeholder="phone model" />
      <input type="text" name="damage-type" placeholder="damage type" />
    </form>
  )
}

export const SimpleForm = () => {
  const { register, handleSubmit, watch, errors } = useForm()
  const onSubmit = subData => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "simple-form",
        ...subData,
      }),
    })
      .then(() => setSubmitted(true))
      .catch(error => alert(error))
  }

  const [submitted, setSubmitted] = useState(false)

  if (submitted) {
    return (
      <StyledSimpleForm onSubmit={handleSubmit(onSubmit)}>
        <h3>Thank You!</h3>
      </StyledSimpleForm>
    )
  }

  return (
    <StyledSimpleForm
      onSubmit={handleSubmit(onSubmit)}
      name="simple-form"
      data-netlify="true"
    >
      <label>Let us message you.</label>
      <div>
        <input
          type="hidden"
          className="hidden"
          name="form-name"
          value="simple-form"
        />
        <Input
          error={errors.email}
          type="email"
          name="email"
          defaultValue=""
          placeholder="email"
          ref={register({ required: true })}
        />
        <button type="submit">
          <Send32 />
        </button>
      </div>
    </StyledSimpleForm>
  )
}
