import React, { useState } from "react"
import styled from "styled-components"
import {
  Mobile32,
  Camera32,
  BatteryHalf32,
  MicrophoneOff32,
  Hearing32,
  Plug32,
  RainDrop32,
  Help32,
  ChevronRight32,
  Laptop32,
  Tablet32,
} from "@carbon/icons-react"
import { useForm } from "react-hook-form"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const DamageListContainer = styled.ul`
  @media (min-width: 800px) {
    width: 30%;
  }
  cursor: pointer;
  padding: 0.4rem 0;
  width: calc(100% - 30px);
  background-color: white;
  color: black;
  border-radius: 4px;
  list-style: none;
  margin: 0 auto;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  li {
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    &:last-child {
      border: none;
    }
  }
  form {
    padding: 1rem 0;
    margin: auto;
    max-width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      label {
        padding: 0 0.4rem;
      }
    }
    label {
      font-size: 0.7rem;
      font-weight: 300;
      line-height: 1;
    }
    input {
      max-width: calc(100% - 60px);
      border: 2px solid #0043ce;
      border-radius: 4px;
      padding: 0.3rem 0.3rem;
      margin: 0.4rem auto;
    }
    button {
      background-color: #0043ce;
      color: #ffffff;
      padding: 0.2rem 0.3rem;
      border-radius: 4px;
      margin: 0.3rem auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
    }
  }
`

const StyledP = styled.p`
  margin: 2rem auto 0 auto;
  font-size: 0.8rem;
`

const Step1 = ({ addFormData, setStep }) => (
  <>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ damage: "screen" } }
        })
        setStep(1)
      }}
    >
      <Mobile32 />
      Screen
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ damage: "camera" } }
        })
        setStep(1)
      }}
    >
      <Camera32 />
      Camera
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ damage: "battery" } }
        })
        setStep(1)
      }}
    >
      <BatteryHalf32 />
      Battery
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ damage: "speaker" } }
        })
        setStep(1)
      }}
    >
      <MicrophoneOff32 />
      Speaker
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ damage: "earpiece" } }
        })
        setStep(1)
      }}
    >
      <Hearing32 />
      Earpiece
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ damage: "charger" } }
        })
        setStep(1)
      }}
    >
      <Plug32 />
      Charging Port
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ damage: "water" } }
        })
        setStep(1)
      }}
    >
      <RainDrop32 />
      Water Damage
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ damage: "other" } }
        })
        setStep(1)
      }}
    >
      <Help32 />
      Other
      <ChevronRight32 />
    </li>
  </>
)

const Step2 = ({ addFormData, setStep }) => (
  <>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ model: "android" } }
        })
        setStep(2)
      }}
    >
      <Mobile32 />
      Android
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ model: "iphone" } }
        })
        setStep(2)
      }}
    >
      <Tablet32 /> IPhone
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ model: "other" } }
        })
        setStep(2)
      }}
    >
      <Laptop32 /> Other
      <ChevronRight32 />
    </li>
  </>
)

const Step3 = ({ data }) => {
  const { register, handleSubmit, watch, errors } = useForm()
  const onSubmit = subData => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "long-form",
        ...subData,
      }),
    })
      .then(() => setSubmitted(true))
      .catch(error => alert(error))
  }

  const [submitted, setSubmitted] = useState(false)
  if (submitted) {
    return <StyledP>Thank You! We'll be in touch shortly.</StyledP>
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} name="long-form">
      <input
        type="hidden"
        className="hidden"
        name="form-name"
        value="long-form"
      />
      <label>
        Once you send us your phone info we will send you a message with an
        estimate of the price and time.
      </label>
      <input
        type="text"
        name="full-name"
        defaultValue=""
        placeholder="full-name"
        ref={register({ required: true })}
      />
      <input
        type="email"
        name="email"
        defaultValue=""
        placeholder="email"
        ref={register({ required: true })}
      />
      <span>
        <input
          type="checkbox"
          name="speak-japanese"
          defaultValue={"true"}
          ref={register}
        />
        <label htmlFor="speak-japanese">I Speak Japanese</label>
      </span>
      <input
        type="hidden"
        name="damage"
        defaultValue={data.damage}
        ref={register}
      />
      <input
        type="hidden"
        name="model"
        defaultValue={data.model}
        ref={register}
      />

      <button type="submit">
        Get Price Quote <ChevronRight32 />
      </button>
    </form>
  )
}

const DamageList = () => {
  const [step, setStep] = useState(0)

  const [formData, addFormData] = useState({})

  return (
    <>
      <form name="long-form" netlify data-netlify="true" hidden>
        <input type="text" name="full-name" />
        <input type="email" name="email" />
        <input type="checkbox" name="speak-japanese" />
        <input type="hidden" name="damage" />
        <input type="hidden" name="model" />
      </form>
      <StyledP>Or select a damage type form the list below.</StyledP>
      <DamageListContainer>
        {step === 0 ? (
          <Step1 addFormData={addFormData} setStep={setStep} />
        ) : step === 1 ? (
          <Step2 addFormData={addFormData} setStep={setStep} />
        ) : (
          <Step3 data={formData} />
        )}
      </DamageListContainer>
    </>
  )
}

export default DamageList
