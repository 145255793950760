import React from "react"
import styled from "styled-components"
import { SimpleForm } from "./forms"
import DamageList from "./damageList"

const StyledHeader = styled.header`
  height: 100%;
  padding: 2rem 1rem;
  text-align: center;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  .button-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
  }
  .button {
    background-color: #0043ce;
    color: #ffffff;
    padding: 0.4rem 0.6rem;
    border-radius: 4px;
    margin: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Header = () => {
  return (
    <StyledHeader>
      <h1>Phone Repairs In Tokyo</h1>
      <p>Got a broken phone or cracked screen? We got you covered!</p>
      <SimpleForm />
      <DamageList />
    </StyledHeader>
  )
}

export default Header
